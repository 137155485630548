
import { defineComponent, onMounted,watch, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import { UsuarioModel } from "@/models/UsuarioModel";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const scrollElRef = ref<null | HTMLElement>(null);
    const block:Ref<boolean> = ref(false);
    const project = ref(JSON.parse(localStorage.getItem('id_project') as any))

    const usuario = <UsuarioModel>store.getters.usuarioLogado;
    const nivelCargo: Ref<number> = ref(Number(usuario.nivelCargo));

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    store.watch(
      () => "store.getters.concessionariaSelectedInfo.modeloAgenda",
      () => {
        const refLinkAgendamentos = document.getElementById("agenda-virtual-sublink-quadro-agendamento");
        const refLinkMultiview = document.getElementById("agenda-virtual-sublink-multiview");

        if (!refLinkAgendamentos || !refLinkMultiview) {
          return;
        }

        if (store.getters.concessionariaSelectedInfo.modeloAgenda == "CampV1") {
          refLinkMultiview.classList.add("d-none");
          refLinkAgendamentos.classList.remove("d-none");
        } else if (store.getters.concessionariaSelectedInfo.modeloAgenda == "HyundaiV1") {
          refLinkAgendamentos.classList.add("d-none");
          refLinkMultiview.classList.remove("d-none");
        } else {
          refLinkAgendamentos.classList.remove("d-none");
          refLinkMultiview.classList.remove("d-none");
        }
      }
    );

    watch(
      () => route.path,
      () => {
        project.value = JSON.parse(localStorage.getItem('id_project') as any)

        if(route.path === "/projects"){
          return block.value = false
        }
        return block.value = true
      }
    )

    onMounted(() => {
      if(route.path === "/projects"){
          return block.value = false
        }
        return block.value = true
    })

   

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      translate,
      router,
      block,
      project,
      nivelCargo
    };
  },
});
