// import { contentWidthFluid } from './../core/helpers/config';
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLayoutConfigStore = defineStore('layoutConfig', {
  state: () => {
    return {
      contentWidthFluid: true
    }
  }
})